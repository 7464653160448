/* Base styles for mobile devices */
body {
  font-family: Arial, sans-serif;
  background-color: #1F1F1F;
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.app {
  text-align: center;
  padding: 10px;
  box-sizing: border-box; /* Include padding in width calculations */
}

.game-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  padding: 10px;
}

.game-item {
  /* background-color: rgba(0, 0, 0, 0.3);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  transition: transform 0.2s;
}

.game-item:hover {
  transform: translateY(-5px);
}

.thumbnail {
  width: 100%;
  border-radius: 8px;
}

h3 {
  margin: 10px 0 5px;
  font-size: 1.2em;
}

.game-player {
  margin: 20px auto;
}

iframe {
  border: none;
  border-radius: 8px;
  max-width: 100%;
  height: auto;
}

/* Footer Styles */
.footer {
  background-color: #1F1F1F;
  color: #FFFFFF;
  text-align: center;
  padding: 15px;
  border-top: 1px solid #333;
}

/* Hide the footer on mobile devices (portrait and landscape) */
@media (max-width: 767px), (max-height: 480px) {
  .footer {
    display: none;
  }
}

/* Footer link styles */
.footer a {
  color: #1E90FF;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}


/* Media Queries for larger screens */
@media(min-width: 600px) {
  .app {
    padding: 20px;
  }

  .game-list {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }

  .game-item {
    padding: 20px;
  }

  h3 {
    margin: 15px 0 10px;
    font-size: 1.5em;
  }

  .footer {
    padding: 20px;
  }
}

/* Media Queries for very large screens */
@media(min-width: 1200px) {
  .game-list {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
