/* General styles for all calculator containers */
.calculator-container {
    background-color: #2c3e50; /* Darker background to match theme */
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 20px auto; /* Centering the container */
    max-width: 600px; /* Maximum width for the container */
    width: 90%; /* Responsive width */
}

/* Header styles for each calculator */
.calculator-header {
    text-align: center;
    color: #ecf0f1; /* Light text for contrast */
    margin-bottom: 20px;
}

/* Input field styles */
.calculator-input {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    font-size: 16px;
    background-color: #34495e; /* Dark input field */
    color: #ecf0f1; /* Light text color for input */
    box-sizing: border-box; /* To include padding in width */
}

/* Select field styles */
.calculator-select {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    font-size: 16px;
    background-color: #34495e; /* Dark select field */
    color: #ecf0f1; /* Light text color for select */
    box-sizing: border-box; /* To include padding in width */
}

/* Button container styles for multiple buttons */
.button-container {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap onto the next line */
    gap: 10px; /* Space between buttons */
}

/* Button styles */
.calculator-button {
    background-color: #2980b9; /* Button color */
    color: white;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    flex: 1 1 calc(50% - 10px); /* Responsive button width (2 per row) with spacing */
    min-width: 120px; /* Minimum button width */
    margin: 5px; /* Add margin to each button */
}

.calculator-button:hover {
    background-color: #3498db; /* Lighter blue on hover */
}

/* Styles for the converted result */
.calculator-result {
    font-size: 18px;
    color: #2ecc71; /* Bright green */
    margin-top: 15px;
}

/* Article styles */
.calculator-article {
    background-color: #34495e; /* Dark article background */
    padding: 15px;
    border-radius: 4px;
    margin-top: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.calculator-article h3 {
    color: #ecf0f1; /* Light text for headings */
}

.calculator-article h4 {
    color: #bdc3c7; /* Lighter gray for subheadings */
}

.calculator-article p {
    color: #ecf0f1; /* Light text for paragraphs */
}

/* Media query for responsiveness */
@media (max-width: 600px) {
    .calculator-container {
        padding: 15px; /* Reduced padding for smaller screens */
    }

    .calculator-button {
        padding: 10px; /* Reduced padding on buttons for smaller screens */
        flex: 1 1 100%; /* Full width for buttons on small screens */
        margin: 5px 0; /* Add vertical margin for better spacing */
    }

    .calculator-header {
        font-size: 18px; /* Slightly smaller font size for the header */
    }

    .calculator-input,
    .calculator-select {
        font-size: 14px; /* Smaller font size for inputs and selects */
    }

    .calculator-result {
        font-size: 16px; /* Smaller font size for results */
    }

    .calculator-article {
        padding: 10px; /* Reduced padding for articles */
    }

    .calculator-article h3 {
        font-size: 16px; /* Smaller font size for article headings */
    }

    .calculator-article h4 {
        font-size: 14px; /* Smaller font size for article subheadings */
    }

    .calculator-article p {
        font-size: 14px; /* Smaller font size for article paragraphs */
    }
}
