  .pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .pagination-controls button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
  }

  .pagination-controls button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .pagination-controls button.active {
    font-weight: bold;
    text-decoration: underline;
  }

  .pagination-controls span {
    margin: 0 5px; /* Space around ellipsis */
    color: white; /* Color of ellipsis */
  }

  .search-input {
    margin: 20px 0;
    padding: 8px;
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .daily-games-container {
  text-align: center;
  margin-bottom: 20px;
}

.daily-games {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjust space between game items */
}

.game-list-container {
  text-align: center; 
  margin-bottom: 20px;
}

.search-input {
  display: block;
  margin: 10px auto;
}


.daily-games-container {
  text-align: center;
  margin-bottom: 20px;
}

.daily-games {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjust space between game items */
}

.game-list-container {
  padding: 20px;
}

.search-input {
  display: block;
  margin: 10px auto;
}

.game-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #555;
}


  