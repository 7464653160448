/* src/components/GamePlayer.css */
.game-player {
  text-align: center;
  margin: 20px;
  position: relative; /* Ensure positioning context for the home button */
}

.iframe-container {
  position: relative;
  width: 100%;
  max-width: 1024px; /* Optional: set a max width for larger screens */
  margin: 0 auto; /* Center the container horizontally */
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Responsive adjustments for smaller screens and landscape */
@media (max-width: 768px) {
  .iframe-container {
    padding-bottom: 100%; /* Adjust aspect ratio for narrower screens */
  }
}

@media (max-width: 480px), (max-height: 480px) {
  .iframe-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 0; /* Remove padding for full-screen view */
    z-index: 999; /* Ensure it stays on top of other content */
  }

  .iframe-container iframe {
    width: 100%;
    height: 100%;
  }

}

/* Special adjustments for landscape mode */
@media (orientation: landscape) and (max-width: 768px) {
  .iframe-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
  }

  .iframe-container iframe {
    width: 100%;
    height: 100%;
  }
}


@media (min-width: 1200px) {
  .iframe-container {
    width: 1024px;
    height: 576px; /* Maintain 16:9 aspect ratio */
    padding-bottom: 0;
  }
}

.thumbnail-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.thumbnail-blur-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: blur(15px);
  z-index: -1;
}

.thumbnail-overlay {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  border-radius: 10px;
  overflow: hidden;
}

.thumbnail-preview {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  margin-bottom: 20px;
}

.play-button {
  padding: 15px 30px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(45deg, #ff6b6b, #f94d6a);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.play-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);
}

.thumbnail-container {
  position: relative;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #555;
}
h1 {
  text-shadow: 2px 2px 4px black; /* Horizontal offset, vertical offset, blur radius, color */
}

.thumbnail-overlay p {
  margin-top: -20px;
  text-shadow: 1px 1px 3px black; /* Horizontal offset, vertical offset, blur radius, color */
}




