/* CategoryFilter.css */

.category-filter {
  margin: 1rem 0; /* Margin for spacing */
  text-align: center; /* Center text */
}

.toggle-button {
  background-color: #000000; /* Button background color */
  color: white; /* Text color */
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s; /* Transition for hover effects */
  margin-bottom: 10px; /* Space between toggle button and category buttons */
}

.toggle-button:hover {
  background-color: #000000; /* Darker shade on hover */
}

.category-buttons {
  display: flex;
  justify-content: center; /* Centering the categories */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.category-button {
  background-color: #2E2E2E; /* Button background color blending with #1F1F1F */
  color: white; /* Text color */
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 5px; /* Spacing between buttons */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s; /* Transition for hover effects */
  flex: 1 1 150px; /* Responsive button size */
  max-width: 200px; /* Max width for buttons */
}

.category-button:hover {
  background-color: #3A3A3A; /* Slightly lighter on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.category-button.active {
  background-color: #000000; /* Highlighted color for the active category */
}
