.article-container {
    padding: 20px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow */
    color: #f8f9fa; /* Light text color for better contrast */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Add transform effect */
}

.article-container:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
}

h1 {
    font-size: 2.5rem;
    color: #fff; /* Warm color for headers */
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Text shadow for depth */
}

h2 {
    font-size: 2rem;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 2px solid #fff; /* Underline for section headers */
    padding-bottom: 5px; /* Padding for a clearer separation */
}

p {
    font-size: 1rem;
    line-height: 1.8;
    color: #eaeaea; /* Softer color for paragraphs */
    margin-bottom: 15px;
}

ul, ol {
    margin-left: 20px;
    margin-bottom: 15px;
    text-align: justify;
    color: #eaeaea;
}

li {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #eaeaea;
    text-align: justify;
    position: relative; /* For custom bullet points */
}

li::before {
    content: "✔️"; /* Custom bullet point */
    position: absolute;
    left: -20px; /* Positioning bullet */
}

strong {
    color: #f8f9fa; /* Maintain contrast for bold text */
}

/* Promotion Section */
.promotion-section {
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
    text-align: center;
    backdrop-filter: blur(5px); /* Blur effect */
}

.promotion-section h2 {
    color: #ff6f61;
    margin-bottom: 10px;
}

.promotion-section p {
    color: #eaeaea;
    margin-bottom: 15px;
}

.promotion-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #28a745; /* Green button color */
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Add transform for button */
}

.promotion-button:hover {
    background-color: #218838; /* Darker green on hover */
    transform: scale(1.05); /* Slightly grow on hover */
}

/* Show AdSense ads in a styled container */
.ads-container-top, .ads-container-middle, .ads-container-bottom {
    margin: 20px 0;
    border-radius: 8px;
    padding: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .article-container {
        padding: 15px;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    p, li {
        font-size: 0.9rem;
    }
}
/* Copy Button Styles */
button {
    padding: 8px 12px; /* Add padding for better click area */
    background-color: #000000; /* Set a green background color */
    color: #ffffff; /* Set text color to white */
    border: none; /* Remove default border */
    border-radius: 5px; /* Round the corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition effects */
}

button:hover {
    background-color: #218838; /* Darker green on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

button:focus {
    outline: none; /* Remove outline on focus */
}
.codes-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .codes-table th,
  .codes-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .codes-table th {
    background-color: #000;
  }
  
  .codes-table tr:nth-child(even) {
    background-color: #000;
  }
  
  .codes-table tr:hover {
    background-color: #e7c5c588;
  }
  
  .codes-table button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .codes-table button:hover {
    background-color: #5BDB5B; 
  }
  
  
  
